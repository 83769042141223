export const LOCATION = {
  AUSTRALIAN: {
    KEY: 'Australian',
    LABEL: {
      SHORT: 'Australia',
      LONG: 'Monash University Australia',
    },
    PAGE: 'australia',
  },
  MALAYSIAN: {
    KEY: 'Malaysian',
    LABEL: {
      SHORT: 'Malaysia',
      LONG: 'Monash University Malaysia',
    },
    PAGE: 'malaysia',
  },
  INDONESIAN: {
    KEY: 'Indonesian',
    LABEL: {
      SHORT: 'Indonesia',
      LONG: 'Monash University Indonesia',
    },
    PAGE: 'indonesia',
  },
  COLLEGE: {
    KEY: 'MonashCollege',
    LABEL: {
      SHORT: 'Monash College',
      LONG: 'Monash College Australia',
    },
    PAGE: 'monash-college',
  },
};

export const LOCATION_KEYS_LIST = Object.values(LOCATION).map(
  (location) => location.KEY
);

export const LOCATION_LABEL = Object.fromEntries(
  Object.values(LOCATION).map((location) => [location.KEY, location.LABEL])
);
