import { AccessibilityContext } from '@monash/portal-frontend-common';
import { Icon, IconButton } from '@monash/portal-react';
import React, { useContext } from 'react';

const DataVisibilityToggle = ({
  updateData,
  data,
  iconColour,
  error,
  props,
}) => {
  const disabled = data?.visible === undefined || error;
  const { setAppLivePoliteMsg, resetAppLiveMsgs } =
    useContext(AccessibilityContext);

  const announceNewVisibleStatus = (newStatus) => {
    resetAppLiveMsgs();
    const dataLabel =
      props?.dataLabel?.charAt(0).toUpperCase() + props?.dataLabel?.slice(1);
    setTimeout(
      () =>
        setAppLivePoliteMsg(
          newStatus ? `${dataLabel} is now shown` : `${dataLabel} is now hidden`
        ),
      200
    );
  };

  return (
    <IconButton
      onClick={() =>
        updateData(
          'visible',
          !data?.visible,
          // on success
          () => announceNewVisibleStatus(!data?.visible),
          // optimistic
          true
        )
      }
      key={props?.dataLabel}
      aria-label={
        data?.visible ? `Hide ${props?.dataLabel}` : `Show ${props?.dataLabel}`
      }
      icon={data?.visible ? Icon.Eye : Icon.EyeClosed}
      color={disabled ? 'var(--card-cta-disabled-text-color)' : iconColour}
      disabled={disabled}
    />
  );
};

export default DataVisibilityToggle;
