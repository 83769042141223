import { Icon } from '@monash/portal-react';
import c from './assessments-warning.module.scss';
import { FeatureContext } from '@monash/portal-frontend-common';
import { useContext } from 'react';

const AssessmentsWarning = () => {
  // feature flags
  const { featureFlags } = useContext(FeatureContext);
  return (
    <div className={c.assessmentsWarning}>
      {featureFlags.GRADES ? (
        <p>
          <Icon.InfoCircle size={18} /> If you're not seeing some of your
          assessments, check Moodle (only assignments, quizzes and workshops
          with a due date appear in the Student Portal). The grades shown here
          are unweighted individual assessment grades (not your final unit
          results).
        </p>
      ) : (
        <>
          <Icon.InfoCircle size={18} /> Some assessments may not be shown.
        </>
      )}
    </div>
  );
};

export default AssessmentsWarning;
