import {
  fsGetDoc,
  fsSetDoc,
  fsUpdateDoc,
} from '@monash/portal-frontend-common';
import WIDGET_ERROR_MESSAGE from '../widget-error/WidgetErrorMessage';
import { getMissingDefaultData } from './get-missing-default-data';
import { formatDefaultData } from '../../widget-library/utils';

/**
 * Initialises the widget doc in firestore if the doc is either non-existent or missing any fields it should have.
 * The purpose is to ensure that all necessary widget data fields are present to avoid problems with existing widgets
 * lacking fields when devs add new widget options to an existing widget module.
 */
export const initWidgetDataDoc = async ({
  widgetId,
  setError,
  widgetDataDocPath,
  widgetModule,
  widget,
}) => {
  try {
    const dataDoc = await fsGetDoc(widgetDataDocPath);
    const data = dataDoc.data();
    const defaultData = formatDefaultData(
      widgetModule?.additionalOptions,
      widget?.typeId
    );

    if (dataDoc.exists()) {
      const missingDefaultData = getMissingDefaultData(data, defaultData);

      // if the widget is missing default data in firestore, try to update the fs doc with the missing data
      if (Object.keys(missingDefaultData).length > 0) {
        try {
          await fsUpdateDoc(widgetDataDocPath, missingDefaultData);
        } catch (error) {
          console.warn(
            `Unable to update firestore with missing default options for widget ${widgetId}`,
            error
          );
          setError(WIDGET_ERROR_MESSAGE.GENERIC);
        }
      }
    }
    // set data doc if it doesn't exist
    else {
      try {
        await fsSetDoc(widgetDataDocPath, defaultData);
      } catch (error) {
        console.warn(
          `Unable to initialise data doc for widget ${widgetId}`,
          error
        );
        setError(WIDGET_ERROR_MESSAGE.GENERIC);
      }
    }
  } catch (error) {
    console.warn(
      `Couldn't retrieve widget (id: ${widgetId}) data document from firestore`,
      error
    );
    setError(WIDGET_ERROR_MESSAGE.GENERIC);
  }
};
