// Local features flags
export const FEATURES = {
  DISPLAY_TIME_GRID: true,
  FORCE_MOCK_API: false,
};

// Feature flags for feature toggles
export const FEATURE_FLAGS = {
  GRADES: true,
  UPDATES: false,
};
