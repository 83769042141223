import React from 'react';
import c from './page-item.module.scss';
import classNames from 'classnames';
import { Icon, fontStyles } from '@monash/portal-react';

const PageItem = ({ name, disabled, selected, onClick, isHidden }) => {
  const classes = classNames(c.pageItem, {
    [c.disabled]: disabled,
  });

  const pageClasses = classNames(c.pageName, {
    [fontStyles.medium]: selected,
  });
  return (
    <button className={classes} onClick={onClick} type="button">
      <div className={c.pageName}>
        {name && <span className={pageClasses}>{name}</span>}
        {selected && <Icon.Check />}
      </div>
      {isHidden && <span className={c.hiddenLabel}>Hidden</span>}
    </button>
  );
};

export default PageItem;
