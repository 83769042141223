import React, { forwardRef } from 'react';
import {
  Button,
  Modal,
  ModalAction,
  ModalSection,
  useMedia,
} from '@monash/portal-react';
import c from './confirmation-modal.module.scss';

const NODE_ID_MODAL_TITLE = 'locationSelectionModalTitle';
const NODE_ID_MODAL_CONTENT = 'locationSelectionModalContent';

const ConfirmationModal = forwardRef(
  (
    {
      open,
      location,
      createDefaultPage,
      closeConfirmationModal,
      completeOnboarding,
    },
    ref
  ) => {
    const size = useMedia();
    const title = `Confirm your selection`;
    const locationDisplay = location?.value?.LABEL.LONG;

    const handleConfirm = () => {
      createDefaultPage(location.value);
      closeConfirmationModal();
      completeOnboarding();
    };

    return (
      <Modal
        open={open}
        onClose={closeConfirmationModal}
        size={size}
        ref={ref}
        dismissOnHistoryNav={true}
        ariaLabel={title}
        ariaDescribedby={null}
        stopPropagationOnDefaultKeyDown={true}
      >
        <ModalSection
          title={title}
          titleTabIndex={null}
          ariaLabelledby={NODE_ID_MODAL_TITLE}
          ariaDescribedby={NODE_ID_MODAL_CONTENT}
        >
          <div>
            <p>You’ve selected {locationDisplay} as your course location.</p>
            <p>
              Once you confirm, you won’t be able to change your course location
              page.
            </p>
          </div>
        </ModalSection>
        <ModalAction position="center">
          <div className={c.modalAction}>
            <div className={c.modalButtons}>
              <Button
                variant="text"
                size="medium"
                onClick={closeConfirmationModal}
              >
                Cancel
              </Button>
              <Button
                size="medium"
                onClick={handleConfirm}
                aria-describedby={NODE_ID_MODAL_CONTENT}
              >
                Confirm {locationDisplay}
              </Button>
            </div>
          </div>
        </ModalAction>
      </Modal>
    );
  }
);

export default ConfirmationModal;
