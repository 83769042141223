import { Icon, RouterState, useResponsiveValue } from '@monash/portal-react';
import { PageContext } from 'components/providers/page-provider/PageProvider';
import { DataContext } from 'components/providers/data-provider/DataProvider';
import { nanoid } from 'nanoid';
import { useContext, useRef, useState } from 'react';
import PageManagementModal from './page-management-modal/PageManagementModal';
import {
  APIContext,
  AccessibilityContext,
  ImpersonationContext,
  deepClone,
  fsUpdateDoc,
} from '@monash/portal-frontend-common';
import { transformPageNameForURL } from 'components/utilities/pages/utils';
import { getNewPageName } from 'components/utilities/pages';
import { MAX_PAGES_NUMBER } from '../../../../../constants';
import { useSnackbar } from 'components/providers/SnackbarProvider';
import c from './page-settings-wrapper.module.scss';
import { LOCATION_KEYS_LIST, LOCATION_LABEL } from 'constants/locations';
import PageSettingsMenuMobile from './page-settings-menu-mobile/PageSettingsMenuMobile';
import PageSettingsMenuDesktop from './page-settings-menu-desktop/PageSettingsMenuDesktop';

export const MOBILE_RESPONSIVE = [
  {
    mq: '(max-width: 699px)',
    value: 'S',
  },
  {
    mq: '(min-width: 700px)',
    value: 'L',
  },
];

const ARIA_LABEL = 'Page management options';

const PageSettingsWrapper = ({ onPagePlaceholder = false }) => {
  const { currentUser } = useContext(ImpersonationContext);
  const { addDefaultPages } = useContext(APIContext);
  const [managingPages, setManagingPages] = useState(false);
  const { redirect } = useContext(RouterState);
  const { setPortalPreferences } = useContext(DataContext);
  const { allPages, pagesData } = useContext(PageContext);
  const { resetAppLiveMsgs } = useContext(AccessibilityContext);
  const { addSnackbar } = useSnackbar();
  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);
  const triggerRef = useRef(null);

  const isMobile = responsiveSize === 'S';
  const triggerLabel = onPagePlaceholder && 'Page management';
  const menuTriggerTrackingLabel = onPagePlaceholder
    ? 'page-placeholder-page-management-setting'
    : 'topnav-page-management-setting';

  const scrollToElement = (path) => {
    document
      .getElementById('VerticalMain')
      ?.scrollTo({ top: document.getElementById(path)?.offsetTop });
  };

  // fs user document
  const userId = currentUser.uid;
  const userPath = `users/${userId}`;

  // reaching page limit
  const reachedPageLimit = allPages?.length >= MAX_PAGES_NUMBER;
  const addReachedPageLimitSnackBar = () => {
    resetAppLiveMsgs();
    addSnackbar({
      message: "You've reached the custom page limit (67/67)",
      type: 'error',
    });
  };

  // add a new page
  const addPage = () => {
    if (reachedPageLimit) {
      // show error message and terminate function
      addReachedPageLimitSnackBar();
      return;
    }

    const newPageId = nanoid();
    const newPageName = getNewPageName(allPages);
    const newPages = deepClone(pagesData);

    // add new custom page
    newPages.customPages = {
      ...newPages.customPages,
      [newPageId]: { name: newPageName, widgetOrder: [] },
    };

    // update page order with new page as the last page
    newPages.pageOrder = [...newPages.pageOrder, newPageId];

    fsUpdateDoc(userPath, {
      'preferences.pages': newPages,
    })
      .then(() => handleAddPageSuccess(newPages, newPageName))
      .catch(handleAddPageError);
  };

  // add a FOR YOU default page
  const addForYouPage = (location) => {
    if (reachedPageLimit) {
      // show error message and terminate function
      addReachedPageLimitSnackBar();
      return;
    }

    if (!location) {
      handleAddPageError('No location found');
    } else {
      addDefaultPages(location)
        .then((response) => {
          const pageName = response.newPageNames[0];
          if (!pageName?.length) {
            handleAddPageError('No page name found');
          } else {
            const updatedPages = response.updatedPreferences.pages;
            handleAddPageSuccess(updatedPages, pageName);
          }
        })
        .catch((error) => {
          handleAddPageError(error);
        });
    }
  };

  const handleAddPageSuccess = (newPages, newPageName) => {
    setPortalPreferences((f) => {
      resetAppLiveMsgs();
      addSnackbar({
        message: `${newPageName} page has been added.`,
        type: 'success',
      });
      return { ...f, pages: newPages };
    });
    redirect(`/page/${transformPageNameForURL(newPageName)}`);

    // Scroll to the page on Mobile
    if (isMobile) {
      setTimeout(() => {
        scrollToElement(`/page/${transformPageNameForURL(newPageName)}`);
      }, 100);
    }
  };

  const handleAddPageError = (error) => {
    resetAppLiveMsgs();
    addSnackbar({
      message: "We can't add a new page right now - please try again later",
      type: 'error',
    });
    console.warn('Api call error, failed to add page', error);
  };

  const userLocations = Object.keys(currentUser.is).filter(
    (location) =>
      LOCATION_KEYS_LIST.includes(location) && currentUser.is[location]
  );

  const hasMultipleLocations = userLocations.length > 1;

  const menuItems = [
    {
      icon: <Icon.Plus size={20} />,
      text: 'Add an empty page',
      function: addPage,
      trackingLabel: 'topnav-add-a-page',
    },
    hasMultipleLocations
      ? {
          icon: <Icon.FilePlus size={20} />,
          text: 'Add default page (FOR YOU)',
          items: userLocations.map((location) => {
            return {
              text: LOCATION_LABEL[location]?.SHORT,
              function: () => addForYouPage(location),
              trackingLabel: `topnav-add-a-default-page-${location.toLowerCase()}`,
            };
          }),
        }
      : {
          icon: <Icon.FilePlus size={20} />,
          text: 'Add default page (FOR YOU)',
          function: () => addForYouPage(userLocations[0]),
          trackingLabel: 'topnav-add-a-default-page',
        },
    {
      icon: <Icon.DragDrop2 size={20} />,
      text: 'Page management',
      haspopup: 'dialog',
      function: () => {
        setManagingPages(true);
      },
      trackingLabel: 'topnav-manage-pages',
    },
  ];

  return (
    <div className={c.pageSettingsMenu}>
      <PageManagementModal
        open={managingPages}
        setOpen={setManagingPages}
        triggerRef={triggerRef}
      />
      {isMobile ? (
        <PageSettingsMenuMobile
          triggerRef={triggerRef}
          items={menuItems}
          title={ARIA_LABEL}
          label={triggerLabel}
          data-tracking-event={menuTriggerTrackingLabel}
        />
      ) : (
        <PageSettingsMenuDesktop
          triggerRef={triggerRef}
          aria-label={ARIA_LABEL}
          items={menuItems}
          label={triggerLabel}
          data-tracking-event={menuTriggerTrackingLabel}
        />
      )}
    </div>
  );
};

export default PageSettingsWrapper;
