import React, { useContext, useRef } from 'react';
import c from './link-tile.module.scss';
import preview from './assets/preview.png';
import empty from './assets/empty.svg';
import WidgetIcon from '../../widget-library/WidgetIcon';
import { Button, Icon } from '@monash/portal-react';
import IconDisplay from '../../components/icon-display/IconDisplay';
import EditLink from './edit-link/EditLink';
import { WidgetContext } from 'components/providers/WidgetProvider';
import EditLinkBackground from './edit-link-background/EditLinkBackground';
import {
  backgroundColorOptions,
  textColorOptions,
} from '../../widget-container/edit-menu/additional-options/colorOptions';

const LinkTile = ({
  widgetId,
  inEditMode,
  data,
  updateData,
  escapeSettingActionsFocusRef,
}) => {
  const link = Array.isArray(data?.link) ? data?.link : [];
  const linkObject = link[0];
  const editLinkButtonRef = useRef();
  const {
    editLinks,
    editActiveWidget,
    editLinksEscapeFocusRef,
    editBackgroundEscapeFocusRef,
  } = useContext(WidgetContext);
  return (
    <div className={c.linkTile}>
      {editActiveWidget?.widgetId === widgetId &&
      editActiveWidget?.editColorAndBackground ? (
        <EditLinkBackground
          widgetId={widgetId}
          data={data}
          updateData={updateData}
          escapeFocusRef={
            editBackgroundEscapeFocusRef || escapeSettingActionsFocusRef
          }
        />
      ) : null}
      {editActiveWidget === widgetId ? (
        <EditLink
          widgetId={widgetId}
          inEditMode={inEditMode}
          updateData={updateData}
          currentLink={linkObject}
          escapeFocusRef={
            editLinksEscapeFocusRef || escapeSettingActionsFocusRef
          }
        />
      ) : null}
      {linkObject ? (
        <a
          className={c.link}
          href={linkObject.url}
          target="_blank"
          rel="noreferrer"
          aria-description="Opens in a new window"
          onKeyDown={(e) => e.stopPropagation()}
        >
          {linkObject.icon ? (
            <IconDisplay icon={linkObject.icon} url={linkObject.url} />
          ) : null}
          <p className={c.linkName}>{linkObject.name}</p>
        </a>
      ) : (
        <div className={c.emptyLink} onKeyDown={(e) => e.stopPropagation()}>
          <div className={c.graphic}>
            <img src={empty} alt="" />
            <h3>No link yet</h3>
          </div>
          <div className={c.editLink}>
            <Button
              ref={editLinkButtonRef}
              variant="text"
              size="small"
              icon={<Icon.Pencil />}
              onClick={() => {
                editLinks(widgetId, editLinkButtonRef);
              }}
              mode="card"
            >
              Set/Edit link
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const LinkTileModule = {
  component: LinkTile,
  name: 'Link tile',
  icon: WidgetIcon.Shortcuts,
  previewImage: preview,
  description: 'Create a fun, custom quick link to your favourite site.',
  previewBackgroundColor: '#FEE2E2',
  defaultSize: 'S',
  getContainerStyles: (data) => {
    const textColor = data?.textColor || 'black';
    const backgroundColor = data?.backgroundColor || 'black';
    const stretchPosition = data?.stretchPosition || false;
    const imageRepeat = data?.imageRepeat || false;
    const backgroundUrl = data?.backgroundURL;

    const textColorValue = textColorOptions.options.find(
      (opt) => opt.id === textColor
    );
    const backgroundColorValue = backgroundColorOptions.options.find(
      (opt) => opt.id === backgroundColor
    );
    return {
      color: textColorValue.value,
      backgroundImage: `url(${backgroundUrl})`,
      backgroundColor: backgroundColorValue?.value,
      backgroundRepeat: imageRepeat ? 'repeat' : 'no-repeat',
      backgroundSize: stretchPosition ? 'cover' : 'auto',
    };
  },

  additionalOptions: [
    {
      key: 'link',
      editType: null,
      default: [],
    },
  ],
  additionalActions: [
    {
      action: 'editLinks',
      icon: <Icon.Pencil size={20} />,
      text: 'Set/Edit link',
      haspopup: 'dialog',
    },
    {
      action: 'editColorAndBackground',
      icon: <Icon.Pencil size={20} />,
      text: 'Edit colours and background',
      haspopup: 'dialog',
    },
  ],
};

export default LinkTileModule;
