// returns missing data for a widget
// to determine missing data, remove keys from the default data that have already been set in fs
// data: user's current widget option data from firestore
// defaultData: widget's default option data
export const getMissingDefaultData = (data, defaultData) => {
  const defaultDataCopy = { ...defaultData };
  for (const key in data) {
    delete defaultDataCopy[key];
  }
  return defaultDataCopy;
};
