import * as Sentry from '@sentry/browser';
import { fsDocListener } from '@monash/portal-frontend-common';
import WIDGET_ERROR_MESSAGE from '../widget-error/WidgetErrorMessage';
import { initWidgetDataDoc } from './init-widget-data-doc';

export const subscribeToWidgetData = async ({
  widgetId,
  setError,
  widgetDataDocPath,
  widgetModule,
  widget,
  setWidgetStoreData,
}) => {
  await initWidgetDataDoc({
    widgetId,
    setError,
    widgetDataDocPath,
    widgetModule,
    widget,
  });

  fsDocListener(
    widgetDataDocPath,
    async (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data();
        setWidgetStoreData(widgetId, data);
      } else {
        console.warn(`Snapshot non-existent for widget ${widgetId}`);
        setError(WIDGET_ERROR_MESSAGE.GENERIC);
      }
    },
    (error) => {
      Sentry.captureException(error);
      console.warn(
        `Couldn't retrieve widget (id: ${widgetId}) data document from firestore`,
        error
      );
      setError(WIDGET_ERROR_MESSAGE.GENERIC);
    }
  );
};
