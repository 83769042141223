import React, { useRef, useState } from 'react';
import {
  useOnOutsideClick,
  useSessionStorage,
} from '@monash/portal-frontend-common';
import Popup from './Popup';
import c from './test-harness.module.scss';
import { Icon } from '@monash/portal-react';

const TestHarness = () => {
  const ref = useRef();
  const [isShowing, setIsShowing] = useState(false);
  const toggle = () => setIsShowing(!isShowing);
  const [testHarness] = useSessionStorage('testHarness');

  useOnOutsideClick({
    refs: [ref],
    fn: () => {
      setIsShowing(false);
    },
  });

  if (!testHarness) {
    return null;
  }

  return (
    <div className={c.testHarness} ref={ref}>
      {isShowing && <Popup isShowing={isShowing} />}
      <button
        type="button"
        onClick={toggle}
        tabIndex={-1}
        className={c.configureButton}
      >
        <Icon.Setting />
      </button>
    </div>
  );
};

export default TestHarness;
