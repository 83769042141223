import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, SearchBox, useResponsiveValue } from '@monash/portal-react';
import DraggableWindow from 'components/ui/draggable-window/DraggableWindow';
import { PageContext } from 'components/providers/page-provider/PageProvider';
import { bySearch, notCurrentPage } from './utils/utils';
import PageItem from './components/page-item/PageItem';
import { getTriggerRefPosition } from '../../utils';
import {
  ImpersonationContext,
  useOnOutsideClick,
} from '@monash/portal-frontend-common';
import { useMoveWidget } from './utils/use-move-widget';

import c from './move-widget-modal.module.scss';
import { MOBILE_RESPONSIVE } from 'components/ui/main/Main';

const MoveWidgetModal = ({
  pageId,
  widgetName,
  widgetId,
  closeModal,
  widgetHeaderRef,
  widgetIndex,
}) => {
  const MENU_WIDTH = 400;
  const containerRef = useRef(null);

  const [selectedPage, setSelectedPageId] = useState({});
  const [searchString, setSearchString] = useState('');
  const { currentUser } = useContext(ImpersonationContext);
  const { pagesData } = useContext(PageContext);

  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);

  const customPages = Object.keys(pagesData?.customPages || {}).map((id) => ({
    id,
    name: pagesData.customPages[id].name,
  }));
  const title = `Move ${widgetName} widget`;

  const pagesFilteredBySearch = customPages.filter(bySearch(searchString));
  const pagesExcludingCurrent = pagesFilteredBySearch.filter(
    notCurrentPage(pageId)
  );
  const isEmptySearch = Boolean(searchString && !pagesFilteredBySearch.length);

  const isEmpty = (obj) => Object.keys(obj).length === 0;
  const isPageInPages = (pages, page) => {
    return pages.some((item) => {
      return Object.keys(page).every((key) => item[key] === page[key]);
    });
  };

  // If selected page is no long in the search results, set selected page to empty
  useEffect(() => {
    if (
      !isEmpty(selectedPage) &&
      searchString &&
      !isPageInPages(pagesFilteredBySearch, selectedPage)
    ) {
      setSelectedPageId({});
    }
  }, [pagesFilteredBySearch, selectedPage]);

  useOnOutsideClick({
    refs: [containerRef],
    fn: closeModal,
  });

  const { call: callMoveWidget, isLoading } = useMoveWidget({
    widgetId,
    widgetName,
    pageId,
    selectedPage,
    currentUser,
    widgetIndex,
  });

  return (
    <DraggableWindow
      ref={containerRef}
      isShowing={true}
      onClose={closeModal}
      title={title}
      focusTrapEnabled={true}
      focusTrapSelectors="button:not([disabled]), input[type=text]"
      className={c.container}
      escapeFocusRef={widgetHeaderRef}
      keyboardDismissalEnabled={true}
      initialPosition={getTriggerRefPosition({
        ref: widgetHeaderRef,
        menuWidth: MENU_WIDTH,
      })}
      fullScreen={responsiveSize === 'S'}
    >
      <div className={c.contentWrapper}>
        <div className={c.searchWrapper}>
          <SearchBox
            searchString={searchString}
            onInput={setSearchString}
            placeholder="Search page"
          />
        </div>

        <div className={c.pageList}>
          {!isEmptySearch && (
            <>
              {pagesExcludingCurrent.map((customPage) => {
                return (
                  <PageItem
                    name={customPage.name}
                    onClick={() => {
                      setSelectedPageId(customPage);
                    }}
                    key={customPage.id}
                    selected={customPage.id === selectedPage.id}
                    isHidden={pagesData?.hiddenPages?.includes(customPage.id)}
                  />
                );
              })}
            </>
          )}
          {isEmptySearch && <div className={c.noResults}>No results</div>}
        </div>

        <div className={c.footerBar}>
          <Button size="small" variant="text" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            size="small"
            onClick={callMoveWidget}
            disabled={!selectedPage.id}
            loading={isLoading}
          >
            Move
          </Button>
        </div>
      </div>
    </DraggableWindow>
  );
};

export default MoveWidgetModal;
