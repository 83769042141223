/**
 * This function can be used to conditionally show/hide default pages.
 * @param currentPages User's current pages in portal preferences
 * @param { [pageId: string]: boolean } showPage: Object with page IDs as keys. True if shown, false if not shown.
 * If a page ID is in the user's current pageOrder but omitted from showPage, it defaults to being shown
 * @returns pages object with transformed pageOrder field
 */
export const handleToggleForDefaultPage = ({ currentPages, showPage = {} }) => {
  const pageOrder = currentPages?.pageOrder || [];

  // Stop the initial flash of just the toggled on page whilst
  // page data is still loading.
  if (!pageOrder.length) {
    return [];
  }

  // Add/remove page order according to showPage arg
  const transformedPageOrder = Object.entries(showPage).reduce(
    (accumPageOrder, [pageId, isShown]) => {
      const hasPageId = accumPageOrder.includes(pageId);

      const pageOrderWithoutPageId = accumPageOrder?.filter(
        (currentPageId) => currentPageId !== pageId
      );

      const pageOrderWithPageId = hasPageId
        ? accumPageOrder
        : [...accumPageOrder, pageId]; // only add page if user doesn't already have it

      return isShown ? pageOrderWithPageId : pageOrderWithoutPageId;
    },
    pageOrder
  );

  return {
    ...currentPages,
    pageOrder: transformedPageOrder,
  };
};
