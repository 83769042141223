import React, { useContext, useEffect, useRef } from 'react';
import c from './important-dates.module.scss';
import WidgetIcon from '../../widget-library/WidgetIcon';
import preview from './preview.png';
import { DataContext } from 'components/providers/data-provider/DataProvider';
import { formatImportantDates } from './utils';
import {
  APIContext,
  ImpersonationContext,
  useSessionStorage,
} from '@monash/portal-frontend-common';
import { Button, Icon, LoadingIndicator } from '@monash/portal-react';
import DateInfo from './date-info/DateInfo';
import calendar from './calendar.svg';
import {
  LOCATION,
  LOCATION_KEYS_LIST,
  LOCATION_LABEL,
} from 'constants/locations';

const SINGLE_DATE_MONTH_LABEL_WIDTH = 45;
const DATE_PERIOD_MONTH_LABEL_WIDTH = 65;

const LOCATION_OPTION_KEY = 'location';
const IMP_DATES_NAME = 'Important dates';

const ImportantDates = ({
  data,
  updateData,
  onSelectedPage,
  setError,
  typeId,
  setEditMenuShown,
}) => {
  const { enrolledTeachingPeriods } = useContext(DataContext);
  const { getImportantDates } = useContext(APIContext);
  const { currentUser } = useContext(ImpersonationContext);
  const [importantDatesData, setImportantDatesData] = useSessionStorage(
    `widgetType:${typeId}`
  );
  const selectedLocation = data?.[LOCATION_OPTION_KEY];

  const importantDates = importantDatesData?.[selectedLocation];

  const hasDatePeriod =
    Array.isArray(importantDates) &&
    importantDates?.find((date) => date.endDate);
  const dateInfoWidth = hasDatePeriod
    ? DATE_PERIOD_MONTH_LABEL_WIDTH
    : SINGLE_DATE_MONTH_LABEL_WIDTH;

  useEffect(() => {
    if (onSelectedPage && selectedLocation && !importantDates?.length) {
      getImportantDates(selectedLocation)
        .then((r) => {
          if (!r?.length) {
            setError('We currently have no important dates to display.');
          } else {
            setImportantDatesData((prevImpDatesData) => {
              return { ...prevImpDatesData, [selectedLocation]: r };
            });
          }
        })
        .catch((error) => {
          console.warn(
            '[getImportantDates]: api call error, failed to get important dates.',
            error
          );
          setError(
            "We can't retrieve important dates right now – please check back later."
          );
        });
    }
  }, [onSelectedPage, selectedLocation]);

  const locationInited = useRef(false);

  useEffect(() => {
    if (!data || locationInited.current) return;

    // init location option if user has a single location, else leave it empty
    if (!selectedLocation) {
      const userLocations = Object.keys(currentUser.is).filter(
        (location) =>
          LOCATION_KEYS_LIST.includes(location) && currentUser.is[location]
      );

      const hasSingleLocation = userLocations.length === 1;

      if (hasSingleLocation) {
        updateData(
          LOCATION_OPTION_KEY,
          userLocations[0],
          () => (locationInited.current = true)
        );
      } else {
        locationInited.current = true;
      }
    } else {
      locationInited.current = true;
    }
  }, [data]);

  const formattedImportantDates = formatImportantDates(
    importantDates,
    enrolledTeachingPeriods
  );

  const months = [
    ...new Set(formattedImportantDates?.map((item) => item.month)),
  ];

  // Considered loading if location option has not been inited, or if location has been explicitly selected but there are still no important dates yet
  const isLoading =
    !locationInited.current || (selectedLocation && !importantDates);

  if (isLoading) {
    return (
      <div className={c.loading}>
        <LoadingIndicator />
      </div>
    );
  }

  const noLocationSelected = locationInited.current && !selectedLocation;

  if (noLocationSelected) {
    return (
      <div className={c.noLocation}>
        <img src={calendar} alt="calendar" />
        <div>No location selected.</div>
        <Button
          icon={<Icon.Pencil />}
          variant="text"
          onClick={() => {
            setEditMenuShown(true);
          }}
        >
          Choose location
        </Button>
      </div>
    );
  }

  return (
    <ul className={c.ImportantDates}>
      {/* Important dates - rendered by month */}
      {months.map((month, i) => (
        <li key={`${month}-${i}`}>
          {/* Sticky month label */}
          <span
            className={c.monthLabel}
            aria-hidden="true"
            style={{
              width: `${dateInfoWidth}px`,
            }}
          >
            <div className={c.stickyContainer}>{month.substring(0, 3)}</div>
          </span>

          <ul
            className={c.monthList}
            aria-label={`${month} important dates`}
            tabIndex="0"
          >
            {/* Render items from one month at a time */}
            {formattedImportantDates
              .filter((item) => item.month === month)
              .map((item, dateIndex) => {
                const dateId = importantDates[dateIndex].id;
                return (
                  <li className={c.importantDateItem} key={dateId}>
                    <DateInfo
                      dateItem={item}
                      firstItem={dateIndex === 0}
                      dateInfoWidth={dateInfoWidth}
                    />
                    {/* Display descriptions belonging to the same day in the same container */}
                    <div className={c.descriptionContainer}>
                      <div className={c.descriptionGroup}>
                        {item.description.map((description, i) => (
                          <div
                            className={c.description}
                            key={`${dateId}-description-${i}`}
                          >
                            <p key={i}>
                              {description.title && (
                                <strong>{description.title} </strong>
                              )}
                              {description.body.map((item, i) =>
                                enrolledTeachingPeriods
                                  .map((item) => item.calType)
                                  .includes(item) ? (
                                  <span
                                    className={c.highlightedLabel}
                                    key={`${dateId}-body-${i}`}
                                  >
                                    {item}
                                  </span>
                                ) : (
                                  item
                                )
                              )}
                            </p>
                            {description.cohort && (
                              <span className={c.highlightedLabel}>
                                {description.cohort}
                              </span>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </li>
      ))}
    </ul>
  );
};

const ImportantDatesModule = {
  component: ImportantDates,
  name: IMP_DATES_NAME,
  getWidgetName: (widgetData) => {
    const selectedLocation = widgetData?.[LOCATION_OPTION_KEY];
    const suffix = LOCATION_LABEL[selectedLocation]?.SHORT;
    return suffix ? `${IMP_DATES_NAME} - ${suffix}` : IMP_DATES_NAME;
  },
  icon: WidgetIcon.ImportantDates,
  previewImage: preview,
  previewBackgroundColor: '#FFEDD5',
  additionalOptions: [
    {
      key: LOCATION_OPTION_KEY,
      name: 'Location',
      editType: 'radio',
      default: null,
      options: [
        {
          value: LOCATION.AUSTRALIAN.KEY,
          label: LOCATION.AUSTRALIAN.LABEL.SHORT,
        },
        {
          value: LOCATION.MALAYSIAN.KEY,
          label: LOCATION.MALAYSIAN.LABEL.SHORT,
        },
        {
          value: LOCATION.INDONESIAN.KEY,
          label: LOCATION.INDONESIAN.LABEL.SHORT,
        },
        { value: LOCATION.COLLEGE.KEY, label: LOCATION.COLLEGE.LABEL.SHORT },
      ],
    },
  ],
  description:
    'Keep up with important uni dates – from census dates to public holidays.',
};

export default ImportantDatesModule;
