import {
  Accordion,
  Button,
  Icon,
  IconButton,
  toggleExpandedIndexes,
  useResponsiveValue,
} from '@monash/portal-react';
import PageContainer from '../PageContainer';
import Stack from 'components/ui/stack/Stack';
import fs from 'styles/font-styles.module.scss';
import UpdateCard from './components/UpdateCard';
import { getCurrentDate } from 'components/providers/data-provider/utils';
import { useEffect, useState, useRef, useContext } from 'react';
import c from './updates.module.scss';
import { isBetweenDates } from 'utils/is-between-dates';
import useOnScreen from 'hooks/use-onscreen';
import { MOBILE_RESPONSIVE } from 'components/ui/main/Main';
import { UpdatesContext } from 'components/providers/updates-provider/UpdatesProvider';
import classNames from 'classnames';
import GenericError from 'components/utilities/error/GenericError';
import useKeyNavGroups, { KEY_NAV_MODE } from 'hooks/use-key-nav-groups';
import {
  DUE_SOON_ID_PREFIX,
  LOAD_UPDATES_INCREMENT,
  UPDATE_ID_PREFIX,
} from './constants';

const Updates = ({ selected }) => {
  const currentDate = getCurrentDate();
  const [dueSoonList, setDueSoonList] = useState([]);
  const [updatesList, setUpdatesList] = useState([]);
  const [openAccordionIndexes, setOpenAccordionIndexes] = useState([0]);
  const { updates: updatesData, error: firestoreError } =
    useContext(UpdatesContext);

  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);
  const isMobile = responsiveSize === 'S';
  const numberOfDueSoon = useRef(0);
  const updatesHeading = dueSoonList.length ? 'More updates' : 'Updates';

  useEffect(() => {
    const dueSoon = updatesData.filter((update) =>
      isBetweenDates(Date.now(), update.startDate, update.endDate)
    );

    if (dueSoon.length !== numberOfDueSoon.current) {
      setOpenAccordionIndexes([0]);
    }

    numberOfDueSoon.current = dueSoon.length;

    const updates = updatesData.filter(
      (update) =>
        !update.startDate ||
        !isBetweenDates(Date.now(), update.startDate, update.endDate)
    );

    setDueSoonList(dueSoon);
    setUpdatesList(updates);
  }, [updatesData]);

  // -- Scroll to top functionality --

  const updatesColumnRef = useRef();
  const scrollDetectorRef = useRef();

  const scrollToTop = () => {
    const firstElement = document.querySelector(
      '[data-id="updates-page-key-nav-group"]'
    );

    firstElement?.focus();

    const updatesColumn = updatesColumnRef.current;

    const scrollOptions = {
      block: 'start',
      behavior: 'instant',
    };

    updatesColumn?.scrollIntoView(scrollOptions);
  };

  const SCROLL_DETECTION_THRESHOLD = 100; // defined here instead of css so we can programatically set it if we want

  // true if the updates column is scrolled above the screen by some threshold
  const isUpdatesAboveScreen = useOnScreen({
    ref: scrollDetectorRef,
    threshold: 0,
  });

  const updatesExist = dueSoonList?.length > 0 || updatesList?.length > 0;
  const showBackToTopButton = updatesExist && isUpdatesAboveScreen && selected;

  const dueSoonWrapperClasses = classNames(c.updatesWrapper, c.dueSoonWrapper);

  // -- Load more functionality --

  const [numUpdatesToShow, setNumUpdatesToShow] = useState(
    LOAD_UPDATES_INCREMENT
  );
  const shownUpdatesList = updatesList.slice(0, numUpdatesToShow);
  const allUpdatesShown = numUpdatesToShow >= updatesList.length;

  const loadMoreUpdates = () => {
    setNumUpdatesToShow(
      (prevNumUpdatesToShow) => prevNumUpdatesToShow + LOAD_UPDATES_INCREMENT
    );
  };

  // Focus on the next update after clicking on load more
  useEffect(() => {
    if (numUpdatesToShow !== LOAD_UPDATES_INCREMENT) {
      const nextUpdateIndex = numUpdatesToShow - LOAD_UPDATES_INCREMENT;
      const nextUpdate = document.querySelector(
        `#${UPDATE_ID_PREFIX}-${nextUpdateIndex}`
      );
      nextUpdate?.focus();
    }
  }, [numUpdatesToShow]);

  useKeyNavGroups({
    rootSelector: `.${c.centeringGrid}`,
    keyNavMode: KEY_NAV_MODE.BOTH,
    groupSelector: '[data-id="updates-page-key-nav-group"]',
  });

  if (firestoreError) {
    return (
      <GenericError>
        <p>We can't show updates right now - check back later</p>
      </GenericError>
    );
  }

  return (
    <PageContainer
      className={c.centeringGrid}
      role="region"
      aria-label="Updates board"
    >
      <div ref={updatesColumnRef} className={c.updatesColumn}>
        {Boolean(dueSoonList.length) && (
          <div role="region" aria-label="Coming up soon">
            <div
              tabIndex={0}
              aria-label="Use the arrow keys to navigate across the board menu and tab key to navigate coming up soon cards"
              data-id="updates-page-key-nav-group"
            >
              <Accordion
                className={c.dueSoonAccordion}
                useCustomStyles
                hasExpandAll={false}
                expandedIndexes={openAccordionIndexes}
                itemsAreTabbable={false}
                onClick={(index) => {
                  setOpenAccordionIndexes(
                    toggleExpandedIndexes(index, openAccordionIndexes)
                  );
                }}
                items={[
                  {
                    title: 'Coming up soon',
                    content: (
                      <Stack className={dueSoonWrapperClasses} gap="1.6rem">
                        {dueSoonList.map((update, index) => (
                          <UpdateCard
                            key={`${update.publishDate}-${update.title}`}
                            title={update.title}
                            description={update.description}
                            links={update.links}
                            isNew={update.isNew}
                            currentDate={currentDate}
                            dueDate={update.endDate}
                            publishDate={update.publishDate}
                            isDueSoon
                            id={`${DUE_SOON_ID_PREFIX}-${index}`}
                          />
                        ))}
                      </Stack>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        )}

        {Boolean(shownUpdatesList.length) && (
          <div role="region" aria-label={updatesHeading}>
            <div
              tabIndex={0}
              aria-label="Use the arrow keys to navigate across the board menu and tab key to navigate update cards"
              data-id="updates-page-key-nav-group"
            >
              <h2 className={`${c.heading} ${fs.md}`}>{updatesHeading}</h2>

              <Stack className={c.updatesWrapper} gap="1.6rem">
                {shownUpdatesList.map((update, index) => (
                  <UpdateCard
                    key={`${update.publishDate}-${update.title}`}
                    title={update.title}
                    description={update.description}
                    links={update.links}
                    isNew={update.isNew}
                    currentDate={currentDate}
                    dueDate={update.endDate}
                    publishDate={update.publishDate}
                    id={`${UPDATE_ID_PREFIX}-${index}`}
                  />
                ))}
              </Stack>
            </div>

            <div className={c.loadMoreWrapper}>
              {allUpdatesShown ? (
                <>There are no more Updates</>
              ) : (
                <Button
                  mode="canvas"
                  variant="secondary"
                  onClick={loadMoreUpdates}
                >
                  Load more updates
                </Button>
              )}
            </div>
          </div>
        )}

        {/* 
          Empty div used to enable/disable the back to top button. Absolutely positioned slightly below the viewport.
          When the top of this div is within/above the viewport, the back to top button is enabled.
          When the top of this div is below the viewport, the back to top button is disabled.
        */}
        <div
          ref={scrollDetectorRef}
          className={c.scrollDetector}
          inert="true"
          style={{
            top: `calc(100dvh + ${SCROLL_DETECTION_THRESHOLD}px`,
            height: `calc(100% - (100dvh + ${SCROLL_DETECTION_THRESHOLD}px))`,
          }}
        />
      </div>

      <div className={c.backToTopButtonContainer}>
        <IconButton
          icon={Icon.ArrowUp}
          className={c.backToTopButton}
          aria-label="Back to top"
          onClick={scrollToTop}
          disabled={!showBackToTopButton}
          data-id="updates-page-key-nav-group"
          tabIndex={0}
          {...(isMobile
            ? {
                mode: 'card',
                variant: 'primary',
              }
            : {
                mode: 'canvas',
                variant: 'secondary',
              })}
        />
      </div>
    </PageContainer>
  );
};

export default Updates;
