import { Icon } from '@monash/portal-react';
import Menu from 'components/ui/menu/Menu';

const PageSettingsMenuDesktop = ({
  triggerRef,
  items,
  label,
  ...restProps
}) => {
  return label ? (
    <Menu
      triggerRef={triggerRef}
      items={items}
      label={label}
      mode="canvas"
      buttonVariant="secondary"
      icon={<Icon.EditTabs />}
      {...restProps}
    />
  ) : (
    <Menu
      triggerRef={triggerRef}
      items={items}
      mode="canvas"
      buttonVariant="icon"
      icon={Icon.EditTabs}
      {...restProps}
    />
  );
};

export default PageSettingsMenuDesktop;
