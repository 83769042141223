import { useEffect, useState } from 'react';

export const useIsBrowserTabActive = () => {
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    const setActive = () => setIsActive(true);
    const setInactive = () => setIsActive(false);

    window.addEventListener('focus', setActive);
    window.addEventListener('blur', setInactive);

    return () => {
      window.removeEventListener('focus', setActive);
      window.removeEventListener('blur', setInactive);
    };
  }, []);

  return isActive;
};
