import React, { useContext } from 'react';
import { FeatureContext } from '@monash/portal-frontend-common';
import { Switch, IconButton, Icon } from '@monash/portal-react';
import c from '../popup.module.scss';

const FEATURE_FLAG_NAMES = {
  CHATBOT: 'Chatbot',
  GRADES: 'Grades',
};

const FeatureToggles = () => {
  const { featureFlags, cachedFeatureFlags, setCachedFeatureFlags } =
    useContext(FeatureContext);

  return (
    <div className={c.tabWrapper}>
      <div className={c.featureFlagsWrapper}>
        {Object.entries(featureFlags)
          .sort((a, b) => (a[0] >= b[0] ? 1 : -1))
          .map(([key, value], i) => (
            <div key={FEATURE_FLAG_NAMES[key] + key}>
              <div className={c.label}>{FEATURE_FLAG_NAMES[key] || key}</div>
              <div className={c.toggle} key={key}>
                <Switch
                  on={value}
                  onClick={() =>
                    setCachedFeatureFlags((f) => ({
                      ...f,
                      [key]: !value,
                    }))
                  }
                />
                {
                  // eslint-disable-next-line no-prototype-builtins
                  cachedFeatureFlags?.hasOwnProperty(key) && (
                    <IconButton
                      aria-label="Revert toggle"
                      key={key}
                      onClick={() =>
                        setCachedFeatureFlags((f) => {
                          const temp = { ...f };
                          delete temp[key];
                          return temp;
                        })
                      }
                      mode="card"
                      size={20}
                      icon={Icon.Box}
                    />
                  )
                }
              </div>

              <hr />
            </div>
          ))}
      </div>
    </div>
  );
};

export default FeatureToggles;
