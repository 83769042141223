import './global.scss';
import React, { useContext, useEffect, useState } from 'react';
import {
  Routes,
  Route,
  SkipToContent,
  SkipToContentItem,
  VirtuallyHidden,
  OfflineIndicator,
} from '@monash/portal-react';
import * as Sentry from '@sentry/browser';
import Nav from 'components/ui/nav/Nav';
import Main from 'components/ui/main/Main';
import ErrorScreen from 'components/utilities/error/ErrorScreen';
import Notification from 'components/ui/notification/Notification';
import SearchResult from 'components/pages/search/SearchResult';
import {
  AuthContext,
  ImpersonationContext,
  useSessionStorage,
} from '@monash/portal-frontend-common';
import { DataContext } from 'components/providers/data-provider/DataProvider';
import PortalPreferencesErrorModal from 'components/ui/portal-preferences-error-modal/PortalPreferencesErrorModal';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import GenericError from 'components/utilities/error/GenericError';
import c from './app.module.scss';
import Onboarding from 'components/pages/onboarding/Onboarding';

const App = () => {
  const { currentUser } = useContext(ImpersonationContext);
  const { userDoc } = useContext(AuthContext);
  const { errors } = useContext(DataContext);

  const [isOffline, setIsOffline] = useState(false);
  const [isOnboarded, setIsOnboarded] = useState(
    userDoc?.history?.onboardingProgress === undefined
  );

  const [showEncumbrances] = useSessionStorage('mock:showEncumbrances');

  useEffect(() => {
    Sentry.setUser({ id: currentUser?.uid });

    window.addEventListener('offline', () => {
      setIsOffline(true);
    });
    window.addEventListener('online', () => {
      setIsOffline(false);
    });
  }, []);

  if (!currentUser?.successfulIdentityResponse) {
    return (
      <ErrorScreen
        heading="Access Denied"
        message="Sorry, you don't have permission to access this application"
        illustration="brokenFlowers"
      />
    );
  }

  const onboarding = (
    <div className="app" id="appWrapper">
      <Route to="/">
        <Onboarding updateOnboarding={setIsOnboarded} />
      </Route>
    </div>
  );

  const [fadeOut, setFadeOut] = useState(false);
  const [isVisible, setIsVisible] = useState(true); // State to control visibility

  useEffect(() => {
    // Set a timeout to trigger the fade out after the component mounts
    const timer = setTimeout(() => {
      setFadeOut(true);
    }, 200);

    // Set another timeout to remove the layer after the fade-out transition
    const removeLayerTimer = setTimeout(() => {
      setIsVisible(false);
    }, 500); // Match this duration with the CSS transition duration

    // Cleanup the timers on unmount
    return () => {
      clearTimeout(timer);
      clearTimeout(removeLayerTimer);
    };
  }, []);

  const nonOnboarding = (
    <div className="app">
      {isVisible && (
        <div className={`${c.transitionLayer} ${fadeOut ? c.fadeOut : ''}`} />
      )}
      <SkipToContent>
        <SkipToContentItem text="Top navigation" skipTo="#topNav" />
        <SkipToContentItem text="Content" skipTo="#contentContainer" />
      </SkipToContent>
      <Nav />
      <div className="app-content-wrapper">
        <Routes matchFirstOnly>
          <Route to="/search" routeProps>
            <VirtuallyHidden element="h1" text="Search" />
            <SearchResult />
          </Route>

          <Route to="/preview">
            <Main />
          </Route>

          <Route to="/">
            <Main />
          </Route>
        </Routes>

        {/* The modal animates twice when using the `open` prop conditionally */}
        {Boolean(errors.portalPreferences) && (
          <PortalPreferencesErrorModal open={true} />
        )}
        {(currentUser.hasEncumbrances || showEncumbrances) && (
          <Notification type="encumbrance" />
        )}
      </div>
      {isOffline && <OfflineIndicator className={c.offlineIndicator} />}
    </div>
  );

  return (
    <SentryErrorBoundary fallback={<GenericError />}>
      {!isOnboarded ? onboarding : nonOnboarding}
    </SentryErrorBoundary>
  );
};

export default App;
