import { useContext, useState } from 'react';
import { moveWidgetInFirestore } from './utils';
import { DataContext } from 'components/providers/data-provider/DataProvider';
import { useSnackbar } from 'components/providers/SnackbarProvider';
import { UndoSnackbarContent } from '../components/undo-snackbar-content/UndoSnackbarContent';
import { useUndoMoveWidget } from './use-undo-move-widget';
import { SNACKBAR_ID_PREFIX } from '../constants';

export const useMoveWidget = ({
  widgetId,
  widgetName,
  pageId,
  selectedPage,
  currentUser,
  widgetIndex,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { refreshPortalPreferences } = useContext(DataContext);

  const { addSnackbar, setSnackbars } = useSnackbar();
  const { call: undoMoveWidget } = useUndoMoveWidget({
    widgetId,
    widgetName,
    pageId,
    selectedPage,
    currentUser,
    widgetIndex,
  });

  const clearMoveWidgetSnackbars = () => {
    setSnackbars((prevSnackbars) =>
      prevSnackbars.filter(
        (snackbar) => !snackbar.id.includes(SNACKBAR_ID_PREFIX)
      )
    );
  };

  const call = async () => {
    setIsLoading(true);
    const id = `${SNACKBAR_ID_PREFIX}-${new Date().getTime().toString()}`;

    try {
      await moveWidgetInFirestore({
        widgetId,
        oldPageId: pageId,
        newPageId: selectedPage.id,
        userId: currentUser.uid,
      });

      await refreshPortalPreferences();

      clearMoveWidgetSnackbars();

      addSnackbar({
        id,
        message: (
          <UndoSnackbarContent
            onUndo={undoMoveWidget}
            widgetName={widgetName}
            selectedPage={selectedPage}
          />
        ),
        type: 'success',
        duration: 150000,
        onClose: () => {
          clearMoveWidgetSnackbars();
        },
      });
    } catch (e) {
      console.error(e);
      addSnackbar({
        message:
          "We're not able to move widgets right now - please try again later",
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    call,
  };
};
