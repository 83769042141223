import React from 'react';
import { createRoot } from 'react-dom/client';
import StickyContainer from 'components/ui/sticky-container/StickyContainer';
import PreviewPanel from 'components/ui/preview-panel/PreviewPanel';
import TestHarness from 'components/ui/test-harness/TestHarness';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  AuthProvider,
  MegaWrapper,
  APIProvider,
  ImpersonationWrapper,
  accessControl,
  LoadingScreen,
  FeatureProvider,
  AccessibilityProvider,
  NotificationProvider,
  ChatBotWrapper,
} from '@monash/portal-frontend-common';
import { Router } from '@monash/portal-react';
import SearchProvider from 'components/providers/SearchProvider';
import DataProvider from 'components/providers/data-provider/DataProvider';
import ThemeProvider from 'components/providers/theme-provider/ThemeProvider';
import PageProvider from 'components/providers/page-provider/PageProvider';
import SnackbarProvider from 'components/providers/SnackbarProvider';
import WidgetProvider from 'components/providers/WidgetProvider';
import homeLoading from 'assets/lotties/home-loading.json';
import { FEATURES, FEATURE_FLAGS } from './constants/features';
import storage from 'utils/storage';
import ScheduleProvider from 'components/providers/ScheduleProvider';
import initMonitoring from './initMonitoring';
import UpdatesProvider from 'components/providers/updates-provider/UpdatesProvider';

const env = process.env.REACT_APP_ENV;
if (env !== 'local') {
  initMonitoring(env);
}

const container = document.getElementById('root');
const root = createRoot(container);

const loadingScreen = <LoadingScreen animation={homeLoading} />;

const wrappers = [
  {
    comp: AuthProvider,
    props: {
      nolanding: true,
      loadingPage: loadingScreen,
      useMocks: FEATURES.FORCE_MOCK_API
        ? process.env.REACT_APP_ENV !== 'prod'
        : storage.session.get('fakeData') &&
          storage.session.get('mock:include:identity'),
      accessControl: accessControl.isStudent,
      getPreferences: true,
    },
  },
  {
    comp: APIProvider,
    props: {
      useMocks: FEATURES.FORCE_MOCK_API
        ? process.env.REACT_APP_ENV !== 'prod'
        : storage.session.get('fakeData'),
    },
  },
  {
    comp: FeatureProvider,
    props: {
      defaultFeatureFlags: FEATURE_FLAGS,
    },
  },
  ImpersonationWrapper,
  ChatBotWrapper,
  Router,
  SearchProvider,
  AccessibilityProvider,
  SnackbarProvider,
  DataProvider,
  ThemeProvider,
  PageProvider,
  UpdatesProvider,
  ScheduleProvider,
  WidgetProvider,
  NotificationProvider,
];

root.render(
  <>
    <MegaWrapper providers={wrappers}>
      <App />
      <StickyContainer>
        <PreviewPanel />
        <TestHarness />
      </StickyContainer>
    </MegaWrapper>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
