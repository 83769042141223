import { DataContext } from 'components/providers/data-provider/DataProvider';
import { useSnackbar } from 'components/providers/SnackbarProvider';
import { useContext } from 'react';
import { moveWidgetInFirestore } from './utils';
import { SNACKBAR_ID_PREFIX } from '../constants';

export const useUndoMoveWidget = ({
  widgetId,
  widgetName,
  pageId,
  selectedPage,
  currentUser,
  widgetIndex,
}) => {
  const { refreshPortalPreferences } = useContext(DataContext);
  const { addSnackbar, setSnackbars } = useSnackbar();

  const clearMoveWidgetSnackbars = () => {
    setSnackbars((prevSnackbars) =>
      prevSnackbars.filter(
        (snackbar) => !snackbar.id.includes(SNACKBAR_ID_PREFIX)
      )
    );
  };

  const call = async () => {
    try {
      await moveWidgetInFirestore({
        widgetId,
        oldPageId: selectedPage.id,
        newPageId: pageId,
        userId: currentUser.uid,
        widgetIndex,
      });

      await refreshPortalPreferences();

      clearMoveWidgetSnackbars();

      addSnackbar({
        message: `Move ${widgetName} widget has been undone`,
        type: 'success',
      });
    } catch (e) {
      console.error(e);
      addSnackbar({
        message: "We're not able to undo right now - please try again later",
        type: 'error',
      });
    }
  };

  return { call };
};
