import { pageIds } from '../../../constants';
const { UPCOMING, SCHEDULE } = pageIds;

export default {
  pages: {
    pageOrder: [UPCOMING, SCHEDULE],
    customPages: {},
    widgets: {},
  },
};
