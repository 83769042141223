import React, { useEffect, useRef, useState } from 'react';
import { Icon, useMedia } from '@monash/portal-react';
import { RenderAnimation } from '@monash/portal-frontend-common';
import useOnScreen from 'hooks/use-onscreen';
import c from './onboarding-carousel.module.scss';
import ConfirmationModal from '../confirmation-modal/ConfirmationModal';
const OnboardingCarousel = ({
  data,
  createDefaultPage,
  activePageIndex,
  updatePageIndex,
  completeOnboarding,
}) => {
  // Handle mobile responsive
  const size = useMedia();
  // const [activePageIndex, setActivePageIndex] = useState(0);
  const [showAnimation, setShowAnimation] = useState(false);
  const activeSlide = data[activePageIndex];
  const bannerRef = useRef();
  const bannerOnScreen = useOnScreen({ ref: bannerRef });
  // TODO: Write this back to fb
  // console.log(activePageIndex);
  // conditions
  const slideCount = data.length;
  const onFirstSlide = activePageIndex === 0;
  const onFinalSlide = activePageIndex === slideCount - 1;
  const hasMultipleSlides = slideCount > 1;
  // nav
  const previousSlide = () => updatePageIndex((f) => (f -= 1));
  const nextSlide = () => updatePageIndex((f) => (f += 1));
  const START_TEXT = 'Get started';
  const DISMISS_TEXT = 'Got it';
  const NEXT_TEXT = 'Next';
  const PREVIOUS_TEXT = 'Back';
  // play animation when banner is on screen

  useEffect(() => {
    if (bannerOnScreen && activeSlide.animation) {
      setShowAnimation(true);
    }
  }, [bannerOnScreen, activeSlide]);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };
  const [isConfirmationModalOpen, setIsConfirmationModalShown] =
    useState(false);
  const confirmSelectedOption = () => {
    setIsConfirmationModalShown(true);
  };
  // Retain focus position when modal closes
  const triggerRef = useRef();
  const closeConfirmationModal = () => {
    setIsConfirmationModalShown(false);
    triggerRef?.current?.focus();
  };

  return (
    <div
      role="region"
      aria-label={`Carousel: ${activeSlide?.title}`}
      className={`${c.onboardingContainer}  ${
        activeSlide?.position === 'center' && c.center
      }`}
    >
      <div
        className={`${c.main} ${activePageIndex === 0 && c.firstPage}`}
        ref={bannerRef}
      >
        <div className={c.textContainer}>
          {activeSlide.subTitle && <span>{activeSlide.subTitle}</span>}
          <span className={c.title}>{activeSlide.title}</span>
        </div>
        <div
          className={c.contentContainer}
          style={{
            flexDirection:
              !activeSlide.options && size === 'S' && 'column-reverse',
          }}
        >
          <p className={c.description}>{activeSlide.description}</p>
          {activeSlide.animation && (
            <div
              className={`${c.animationContainer} ${size === 'M' && c.medium} ${
                size === 'S' && c.small
              }`}
            >
              {showAnimation ? (
                <RenderAnimation
                  className={c.animation}
                  lottieFile={activeSlide.animation}
                />
              ) : null}
            </div>
          )}
          {activeSlide.options && (
            <div className={c.optionContainer}>
              <div className={`${c.options} ${size === 'S' && c.mobile}`}>
                {activeSlide.options.map((option, index) => (
                  <button
                    type="button"
                    className={`${c.option} ${
                      option?.value === selectedOption?.value && c.selected
                    } ${index % 2 !== 0 && c.flipped}`}
                    key={option?.value.LABEL.SHORT}
                    onClick={() => handleOptionSelect(option)}
                    style={{
                      backgroundImage:
                        option.image && option?.value === selectedOption?.value
                          ? `url(${option.image?.default || option.image})`
                          : 'none',
                    }}
                  >
                    <span
                      className={`${c.text}  ${index % 2 !== 0 && c.flipped}`}
                    >
                      {option.value?.LABEL?.LONG}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={`${c.navContainer} ${size === 'S' && c.mobile}`}>
        {/* view first page  */}
        {hasMultipleSlides && onFirstSlide && (
          <button
            type="button"
            className={`${c.actionButton} ${onFirstSlide && c.firstPage} ${
              c.active
            }`}
            onClick={nextSlide}
            aria-label={START_TEXT}
            data-tracking-event={`banner-${START_TEXT.toLowerCase()}`}
          >
            {START_TEXT}
          </button>
        )}
        {/* view previous page  */}
        {hasMultipleSlides && !onFirstSlide && (
          <button
            type="button"
            className={`${c.actionButton} ${c.text} ${c.iconLeft}`}
            onClick={previousSlide}
            aria-label={PREVIOUS_TEXT}
            data-tracking-event={`banner-${PREVIOUS_TEXT.toLowerCase()}`}
          >
            <Icon.ChevronLeft />
            {PREVIOUS_TEXT}
          </button>
        )}
        {/* view next banner */}
        {hasMultipleSlides && !onFirstSlide && !onFinalSlide && (
          <button
            type="button"
            className={`${c.actionButton} ${c.iconRight}`}
            onClick={nextSlide}
            aria-label={NEXT_TEXT}
            data-tracking-event={`banner-${NEXT_TEXT.toLowerCase()}`}
          >
            <Icon.ChevronRight />
            {NEXT_TEXT}
          </button>
        )}
        {/* dismiss banner */}
        {hasMultipleSlides && onFinalSlide && !activeSlide.options && (
          <button
            type="button"
            className={c.actionButton}
            onClick={() => completeOnboarding()}
            aria-label={DISMISS_TEXT}
            data-tracking-event={`banner-${DISMISS_TEXT.toLowerCase()}`}
          >
            {DISMISS_TEXT}
          </button>
        )}
        {/* dismiss banner */}
        {hasMultipleSlides && onFinalSlide && activeSlide.options && (
          <button
            type="button"
            className={c.actionButton}
            onClick={confirmSelectedOption}
            disabled={!selectedOption}
            ref={triggerRef}
            aria-label={selectedOption?.value}
            data-tracking-event={`banner-${DISMISS_TEXT.toLowerCase()}`}
          >
            Select
          </button>
        )}
      </div>
      <ConfirmationModal
        open={isConfirmationModalOpen}
        location={selectedOption}
        closeConfirmationModal={closeConfirmationModal}
        createDefaultPage={createDefaultPage}
        completeOnboarding={completeOnboarding}
      />
    </div>
  );
};
export default OnboardingCarousel;
