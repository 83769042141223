import React, { useContext, useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/browser';
import fs from 'styles/font-styles.module.scss';
import WidgetIcon from '../../widget-library/WidgetIcon';
import preview from './preview.png';
import { InlineExternalLink, LoadingIndicator } from '@monash/portal-react';
import { DataContext } from 'components/providers/data-provider/DataProvider';
import { createPortal } from 'react-dom';
import NewsCard from './news-card/NewsCard';
import { getNewsData, getChannelInfo } from './utils';
import { isValid } from 'date-fns';
import noNews from './assets/no-channels.svg';
import { isObject, useSessionStorage } from '@monash/portal-frontend-common';
import useContainerWidth, {
  BOX_SIZE_MODE,
  CONTAINER_WIDTH,
} from 'hooks/use-container-width';
import c from './news.module.scss';
import { ThemeContext } from 'components/providers/theme-provider/ThemeProvider';
import { formatPublishDate } from 'components/utilities/format-publish-date';

const CHANNELS_KEY = 'channels';

/**
 * Generate an array of news channel id strings that should be shown on the widget according to
 * user's selected channels.
 * Requires both the channel options module and the channel data from firestore.
 * If channel data is not found for a particular defined option, we treat the option as 'on', and
 * therefore, we show that channel's news items
 * @param {Array} channelsOptions
 * @param {Object} channelsData
 * @returns Array of channel id's
 */
const getActiveChannelIds = (channelsOptions, channelsData) => {
  if (!Array.isArray(channelsOptions) || !isObject(channelsData)) {
    return [];
  }

  // for each channel option, if channel option is 'on', add it to string
  return channelsOptions.reduce((acc, curr) => {
    const newsChannelId = curr.id;

    // channel option is 'on' if it's true or if it's not found in the option data
    if (channelsData[newsChannelId] || !(newsChannelId in channelsData)) {
      acc.push(newsChannelId);
    }

    return acc;
  }, []);
};

const News = ({ data, updateData, typeId, additionalOptions }) => {
  // Convert channel data array into an object
  // TODO: this can be removed once a script is run to convert all existing news channel data from an array to an object
  useEffect(() => {
    if (!data) return;

    if (!(CHANNELS_KEY in data)) {
      console.warn('channels not found in data');
      return;
    }

    const channels = data.channels;

    if (Array.isArray(channels)) {
      // if channels is an array, convert it to object and init the international student news channel to true
      const channelsObject = {
        monash_university: false,
        student_portal: false,
        international_student_news: true,
      };

      // preserve the user's current selection
      channels.forEach((channelId) => {
        if (channelId in channelsObject) {
          channelsObject[channelId] = true;
        }
      });

      updateData(CHANNELS_KEY, channelsObject);
    }
  }, [data]);

  const { profileData, userCourses, currentDate } = useContext(DataContext);
  const { selectedTheme } = useContext(ThemeContext);

  const channelsOptions = additionalOptions.find(
    (optModule) => optModule.key === CHANNELS_KEY
  )?.options;

  const activeChannelIds = getActiveChannelIds(channelsOptions, data?.channels);

  const [typeData, setTypeData] = useSessionStorage(`widgetType:${typeId}`, {});
  const channelString = activeChannelIds.join('+') || '';
  const [newsData, setNewsData] = useState(typeData[channelString]);
  const [loading, setLoading] = useState(!typeData[channelString]);

  // news card states
  const [displayData, setDisplayData] = useState(null);
  const [isNewsCardShown, setIsNewsCardShown] = useState(false);
  const [activeNewsRef, setActiveNewsRef] = useState(null);

  // refs
  const newsItemRefs = useMemo(
    () => newsData?.map(() => React.createRef()),
    [newsData]
  );

  // resizing
  const { containerCallbackRef, size } = useContainerWidth({
    sizes: [
      { name: CONTAINER_WIDTH.SMALL, maxWidth: 600 },
      { name: CONTAINER_WIDTH.LARGE },
    ],
    boxSizeMode: BOX_SIZE_MODE.CONTENT,
  });

  // open news card
  const showNewsCard = (itemRef, itemData) => {
    setIsNewsCardShown(true);
    setActiveNewsRef(itemRef);
    setDisplayData(itemData);
  };

  // get and display news data
  useEffect(() => {
    if (data && profileData && userCourses) {
      if (typeData[channelString]) {
        setNewsData(typeData[channelString]);
        setLoading(false);
      } else {
        setLoading(true);
        getNewsData(activeChannelIds, profileData, userCourses)
          .then((r) => {
            setNewsData(r);
            setTypeData({ ...typeData, [channelString]: r });
          })
          .catch((error) => {
            Sentry.captureException(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [data, profileData, userCourses]);

  const displayNews = (newsItem, i) => {
    const channelInfo = getChannelInfo(
      newsItem.data.channelName,
      selectedTheme
    );
    const publishedTime = new Date(newsItem.published);
    const dateIsValid = isValid(publishedTime);
    const header = (
      <div className={c.header}>
        <div className={c.channelIcon}>{channelInfo?.icon}</div>
        <div className={c.channelName}>
          <p className={fs.label}>{channelInfo?.name}</p>
          <p className={`${c.time} ${fs.label}`}>
            {dateIsValid && formatPublishDate(currentDate, publishedTime)}
          </p>
        </div>
      </div>
    );

    const hasImage = !(Object.keys(newsItem.data.image).length === 0);

    const image = hasImage && (
      <div className={c.imgContainer}>
        <img
          className={c.image}
          src={newsItem.data.image.medium}
          alt={newsItem.data.title} // AC: this alt text should be unique to the image - should not be a copy of the title.
        />
      </div>
    );

    const clampClass =
      !hasImage && size === CONTAINER_WIDTH.LARGE ? c.smallText : c.largeText;

    const title = (
      <h3 className={`${c.title} ${clampClass}`}>{newsItem.data.title}</h3>
    );

    const content = (
      <p className={`${c.description} ${clampClass}`}>
        {newsItem.data.description}
      </p>
    );
    const link = !(Object.keys(newsItem.data.links).length === 0) && (
      <div>
        {newsItem.data.links.map((link, i) => (
          <InlineExternalLink
            key={i}
            text={link.label}
            link={link.url}
            mode="card"
            variant="text"
            data-tracking-event-location="news-widget"
          />
        ))}
      </div>
    );

    const ariaHeading = channelInfo?.name;

    return (
      <div className={c.newsItem} key={i}>
        <button
          type="button"
          className={[c.clickable, c[size]].join(' ')}
          tabIndex={0}
          ref={newsItemRefs[i]}
          aria-haspopup="dialog"
          onClick={() =>
            showNewsCard(newsItemRefs[i], {
              header,
              title,
              image,
              content,
              link,
              ariaHeading,
            })
          }
          data-tracking-event="custom-widget-news"
          data-label={newsItem.data.title}
        >
          <div className={c.content}>
            {header}
            {size === CONTAINER_WIDTH.SMALL && image}
            {title}
            {content}
          </div>
          {size === CONTAINER_WIDTH.LARGE && image}
        </button>
        <div className={c.links}>{link}</div>
      </div>
    );
  };

  const emptyDisplay = (
    <div className={c.loading}>
      <img src={noNews} alt="" />
      <p>
        {data?.channels?.length === 0
          ? 'No news channels selected.'
          : 'No news available.'}
      </p>
    </div>
  );

  return (
    <div className={c.newsContainer} ref={containerCallbackRef}>
      {createPortal(
        <NewsCard
          shown={isNewsCardShown}
          setShown={setIsNewsCardShown}
          returnRef={activeNewsRef}
          displayData={displayData}
        />,
        document.body
      )}
      {loading ? (
        <div className={c.loading}>
          <LoadingIndicator />
        </div>
      ) : data?.channels?.length === 0 ||
        !newsData ||
        newsData?.length === 0 ? (
        emptyDisplay
      ) : (
        newsData.map((item, i) => displayNews(item, i))
      )}
    </div>
  );
};

const NewsModule = {
  component: News,
  name: 'News',
  icon: WidgetIcon.News,
  previewImage: preview,
  description: 'Stay up-to-date with uni news and events.',
  previewBackgroundColor: '#E8EEF4',
  additionalOptions: [
    {
      key: 'channels',
      name: 'Channels',
      editType: 'switches',
      default: {
        monash_university: true,
        student_portal: true,
        international_student_news: true,
      },
      options: [
        { id: 'monash_university', name: 'Monash University' },
        { id: 'student_portal', name: 'Student Portal' },
        {
          id: 'international_student_news',
          name: 'International Student News',
        },
      ],
    },
  ],
};

export default NewsModule;
