import c from './page-settings-menu-mobile.module.scss';
import { isStringEmpty } from '@monash/portal-frontend-common';
import {
  Accordion,
  Button,
  fontStyles,
  Icon,
  IconButton,
  Modal,
  ModalSection,
} from '@monash/portal-react';
import classNames from 'classnames';
import { useState } from 'react';

const PageSettingsMenuMobile = ({
  triggerRef,
  items,
  title,
  label,
  ...restProps
}) => {
  const [isMenuShown, setIsMenuShown] = useState(false);
  const [refreshModalFocusablesCount, setRefreshModalFocusablesCount] =
    useState(0);

  const openMenu = () => setIsMenuShown(true);
  const closeMenu = () => setIsMenuShown(false);

  const renderMenuItem = ({ item, index, isSubItem = false }) => {
    const itemHasPopup = !isStringEmpty(item.haspopup);

    return (
      <button
        key={index}
        type="button"
        className={classNames(c.menuListItem, fontStyles.body, {
          [c.subItem]: isSubItem,
        })}
        onClick={(event) => {
          item.function?.(event);
          closeMenu();
        }}
        style={{
          color: `var(${
            item.attention ? '--color-intent-attention' : '--card-text-color'
          })`,
        }}
        role="menuitem"
        aria-haspopup={itemHasPopup ? item.haspopup : null}
        data-tracking-event={item.trackingLabel}
      >
        {item.icon}
        {item.text}
      </button>
    );
  };

  return (
    <>
      {label ? (
        <Button
          ref={triggerRef}
          icon={<Icon.EditTabs />}
          mode="canvas"
          variant="secondary"
          size="medium"
          onClick={openMenu}
          aria-haspopup="dialog"
          {...restProps}
        >
          {label}
        </Button>
      ) : (
        <IconButton
          ref={triggerRef}
          icon={Icon.EditTabs}
          mode="canvas"
          variant="text"
          onClick={openMenu}
          aria-haspopup="dialog"
          {...restProps}
        />
      )}
      <Modal
        open={isMenuShown}
        onClose={closeMenu}
        ariaLabel={title}
        ariaDescribedby={null}
        refreshFocusables={refreshModalFocusablesCount}
      >
        <ModalSection
          title={title}
          noPadding
          ariaLabelledby={null}
          ariaDescribedby={null}
        >
          <ul className={c.menuList} role="menu">
            {items.map((item, i) => {
              const subItems = item.items;

              if (subItems) {
                return (
                  <Accordion
                    key={i}
                    useCustomStyles
                    hasExpandAll={false}
                    className={c.submenuAccordion}
                    onClick={() =>
                      setRefreshModalFocusablesCount(
                        (prevCount) => prevCount + 1
                      )
                    }
                    items={[
                      {
                        title: (
                          <span
                            className={classNames(
                              c.menuListItem,
                              fontStyles.body
                            )}
                          >
                            {item.icon}
                            {item.text}
                          </span>
                        ),
                        content: subItems.map((subItem, i) =>
                          renderMenuItem({
                            item: subItem,
                            index: i,
                            isSubItem: true,
                          })
                        ),
                      },
                    ]}
                  />
                );
              }

              return renderMenuItem({ item, index: i });
            })}
          </ul>
        </ModalSection>
      </Modal>
    </>
  );
};

export default PageSettingsMenuMobile;
