import React, { useContext } from 'react';
import c from './disclaimer.module.scss';
import { Icon } from '@monash/portal-react';
import { FeatureContext } from '@monash/portal-frontend-common';

const Disclaimer = () => {
  // feature flags
  const { featureFlags } = useContext(FeatureContext);
  return (
    <label className={c.disclaimer}>
      {featureFlags.GRADES ? (
        <>
          <span>
            <Icon.InfoCircle size={18} />
          </span>
          Only assignments, quizzes and workshops with a due date appear here.
          Grades shown are unweighted individual assessment grades (not your
          final unit results).
        </>
      ) : (
        <>
          <Icon.Alert size={16} /> Some assessments may not be shown.
        </>
      )}
    </label>
  );
};

export default Disclaimer;
