import React, { useContext, createContext, useState, useMemo } from 'react';
import { useTransition, animated } from '@react-spring/web';
import { Snackbar } from '@monash/portal-react';
import { AccessibilityContext } from '@monash/portal-frontend-common';

const DEFAULT_LIVE_MSG_DELAY_POLITE = 1500;
const DEFAULT_LIVE_MSG_DELAY_ASSERTIVE = 5000;

export const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const refMap = useMemo(() => new WeakMap(), []);
  const [snackbars, setSnackbars] = useState([]);
  const { setAppLiveAssertiveMsg, setAppLivePoliteMsg } =
    useContext(AccessibilityContext);

  const transitions = useTransition(snackbars, {
    from: { transform: 'translateY(-20px)', opacity: 0, maxHeight: 0 },
    enter: (item) => async (next) => {
      await next({
        transform: 'translateY(0px)',
        opacity: 1,
        maxHeight: refMap.get(item)?.offsetHeight || 40,
      });
    },
    leave: { transform: 'translateY(-20px)', opacity: 0, maxHeight: 0 },
    keys: snackbars.map((snackbar) => snackbar.id),
  });

  const addSnackbar = ({
    id,
    message,
    type = 'info',
    liveMsgDelay = null,
    duration = 3000,
    onClose,
  }) => {
    // create new and add to queue
    const uid = id || new Date().getTime().toString();
    setSnackbars((prevSnackbars) => [
      ...prevSnackbars,
      {
        id: uid,
        message,
        type,
        onClose,
      },
    ]);

    // setup removal in time
    setTimeout(() => removeSnackbar(uid), duration);
    const shouldLiveMsgBeAssertive = type === 'error' || type === 'warning';
    const defaultLiveMsgDelay = shouldLiveMsgBeAssertive
      ? DEFAULT_LIVE_MSG_DELAY_ASSERTIVE
      : DEFAULT_LIVE_MSG_DELAY_POLITE;

    const announceLiveMsg = () => {
      const setAppLiveMsg = shouldLiveMsgBeAssertive
        ? setAppLiveAssertiveMsg
        : setAppLivePoliteMsg;
      setAppLiveMsg(message);
    };

    setTimeout(announceLiveMsg, liveMsgDelay || defaultLiveMsgDelay);
  };

  const removeSnackbar = (uid) => {
    setSnackbars((snackbars) =>
      snackbars.filter((snackbar) => snackbar.id !== uid)
    );
  };

  return (
    <SnackbarContext.Provider
      value={{ snackbars, addSnackbar, removeSnackbar, setSnackbars }}
    >
      <div
        style={{
          width: 'fit-content',
          position: 'fixed',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          top: '80px',
          padding: '0 16px',
          right: 0,
          left: 0,
          margin: '0 auto',
          zIndex: 999, // To go above all layers
        }}
      >
        {transitions((style, item) => (
          <animated.div style={style}>
            <div ref={(ref) => ref && refMap.set(item, ref)}>
              <Snackbar {...item} />
            </div>
          </animated.div>
        ))}
      </div>

      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);

  if (!context) {
    throw new Error(
      'useSnackbarContext must be used within a SnackbarProvider'
    );
  }

  return context;
};

export default SnackbarProvider;
